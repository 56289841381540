<template>
  <div class="guide-cnt13">
    <h6 class="h6">返工/退换货</h6>
    <div class="cnt">
      <p class="p pm">1、微微定网站同意退换货的情况：微微定网站印刷、后期制作、装订的产品严格遵照中华人民共和国出版行业标准《印刷产品质量评价和分等导则》（CY/T2-1999）的质量标准执行；当发生质量问题经过核实为微微定网站原因的，我们会为您办理退货或者重印；</p>
      <p class="p pm">2、对于需要办理退货或者重印的，自产品交货日（以实际收货日期为准）起3日内可以办理；</p>
      <p class="p">3、退换货涉及的运费谁来支付？</p>
      <p class="p">1）客户购买非个性定制化商品，非质量问题申请的退换货，退回和换货所产生的物流运费由客户承担；</p>
      <p class="p pm">2）如退换货发生时，因暂不支持运费到付的情况，请您先垫付运费，申请退换货的商品如判定为由微微定质量问题所产生，我们将即时退还您垫付运费并且免费为您配送。</p>
      <p class="p">4、微微定网站不予办理退换货的情况： </p>
      <p class="p">1）非微微定网站原因造成的产品质量不良 包括：非微微定网站原因造成的印刷稿版式或文字有错误、图片精度未达到300dpi的等；</p>
      <p class="p">2）产品签收后产生的受潮、染色等；</p>
      <p class="p">3）退回产品数量不足；</p>
      <p class="p">4）微微定网站准时出货，因物流公司原因造成货物晚到的； </p>
      <p class="p pm">5）其他由于用户原因造成的。</p>
      <p class="p">注：印刷色差CMYK值误差10%以内，微微定不给予补印或退货。因采用拼版印刷会有印刷色差，同一印刷文件，在不同次印刷时色彩都会有差异，这些属于拼版印刷正常现象，如果您对颜色有严格要求请联系客服选用专版印刷。</p>
      <p class="p">切成品误差2mm，请检查内容到边是否有足够的距离，在合理的范围内的误差本公司不给予退货或补印。 建议内容到边的距离最少为4mm。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'guide-cnt13',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.guide-cnt13 {
  width: 946px;float:right;background: #fff;border-radius: 5px;min-height: 600px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .p {
      font-size: 14px;color:#333;margin-bottom:10px;
    }
    .pm {
      margin-bottom:30px;
    }
  }
}
</style>
